import React, { useState, useEffect } from 'react';
import './App.css'; 
import Header from './components/AfterLogin/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Evenementen from './components/AfterLogin/Evenementen/evenementen';
import Login from './components/Login/Login';
// eslint-disable-next-line no-unused-vars
import LedenLijst from './components/AfterLogin/Renner/LedenLijst';
import SessionHandler from './Sessionhandler/SessionHandler';
import NieuwLid from './components/AfterLogin/Renner/NieuwLid';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import NieuwEvenement from './components/AfterLogin/Evenementen/NieuwEvenement';
import WachtwoordVergeten from './components/Login/WachtwoordVergeten';
import ResetPasword from './components/Login/ResetPasword';
import Groepen from './components/AfterLogin/Groepen/Groepen';
import ActivateAccount from './components/Login/ActivateAccount';
import NieuwGroep from './components/AfterLogin/Groepen/NieuwGroep';
import Instellingenmainpage from './components/AfterLogin/Instellingen/InstellingenMainPage';
import AccountSettings from './components/AfterLogin/Instellingen/AccountSettings';
import HOME from './components/BeforeLogin/HomePage'
import ProtectedRoute from './Sessionhandler/ConnectionTestRoute';
import Homepage from './components/AfterLogin/HomePage'
import Contact from './components/BeforeLogin/Legal/Contact'
import Prijs from './components/BeforeLogin/Legal/Prijs'
import TOS from './components/BeforeLogin/Legal/TOS'
import PrivacyPolicy from './components/BeforeLogin/Legal/Privacy';

// Disable React DevTools in production
if (process.env.REACT_APP_NODE_ENV === 'production') {
  disableReactDevTools();
}

function App() {
  if (!SessionHandler.checklogin()) {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="" element={<HOME/> } />
            <Route path="/WachtwoordVergeten" element={<ProtectedRoute element={WachtwoordVergeten} />} />
            <Route path="/resetpassword" element={<ProtectedRoute element={ResetPasword} />} />
            <Route path="/activateaccount" element={<ProtectedRoute element={ActivateAccount }/>} />
            <Route path="/Login" element={<ProtectedRoute element={Login}/>}/>
            <Route path="*" element={<HOME />} />
            <Route path="/contact" element= {<Contact />}/>
            <Route path="/prijs" element= {<Prijs />}/>
            <Route path="/TermsofService" element = {<TOS />} />
            <Route path="/Privacy" element = {<PrivacyPolicy />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  } else {
    return (
      <div className="App">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/evenementen" element={<ProtectedRoute element= {Evenementen} />} />
            <Route path="/NieuwLid" element={<ProtectedRoute element= {NieuwLid} />} />
            <Route path="/leden" element={<ProtectedRoute element= {LedenLijst} />} />
            <Route path="/NieuwEvenement" element={<ProtectedRoute element= {NieuwEvenement} />} />
            <Route path="/Groepen" element={<ProtectedRoute element= {Groepen} />} />
            <Route path="/NieuweGroep" element={<ProtectedRoute element= {NieuwGroep} />} />
            <Route path="/Settings" element={<Instellingenmainpage />} />
            <Route path="/Settings/AccountSettings" element={<ProtectedRoute element= {AccountSettings} />} />
            <Route path="*" element={<Homepage />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
