import axios from 'axios'
import SessionHandler from '../Sessionhandler/SessionHandler'
axios.defaults.headers.common.Authorization = 'Bearer ' + SessionHandler.getToken()
axios.defaults.headers.common['ClubID'] = SessionHandler.GetClub();

const LID_API_BASE_URL = process.env.REACT_APP_API_URL + 'lid'

class LidService {
  GetAll () {
    return axios.get(LID_API_BASE_URL + '/All')
  }

  async GetByID(id) {
    
    return await axios.get(LID_API_BASE_URL+ '/Byid/' + id)
  }

  async nieuw (lid) {
    return await axios({
      method: 'post',
      url: LID_API_BASE_URL + '/nieuwlid',
      headers: {},
      data: {
        voornaam: lid.voornaam,
        achternaam: lid.achternaam,
        adres: lid.adres,
        woonplaats: lid.woonplaats,
        geboortedatum: lid.geboortedatum,
        telnummer: lid.telnummer,
        geslacht: lid.geslacht,
        email: lid.email,
        groep: lid.groep,
        wachtwoord: lid.wachtwoord,
        icenaam1: lid.icenaam1,
        icenummer1: lid.icenummer1,
        icenaam2: lid.icenaam2,
        icenummer2: lid.icenummer2,
        emailouders: lid.emailouders,
      }
    }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        return (error.response)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      console.log(error.config)
    })
  }

  async UpdateClubConnectie(lid) {
    console.log("lid object:", lid); // Check the incoming lid object
  
    if (lid.groep == undefined) {
      console.log("groep is undefined, setting to 0");
      lid.groep = 0;
    }
  
    if (lid.rol && lid.rol.naam !== undefined) {
      lid.rol = lid.rol.naam;
    }
  
    console.log("rol:", lid.rol);
    console.log("groep:", lid.groep);
  
    return await axios({
      method: 'patch',
      url: LID_API_BASE_URL + '/Update/ClubConnectie',
      headers: {},
      data: {
        id: lid.id,
        groep: lid.groep,
        rol: lid.rol
      }
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        return (error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }


  async Update (lid) {
    return await axios({
      method: 'patch',
      url: LID_API_BASE_URL + '/Update/Info',
      headers: {},
      data: {
        id: lid.id,
        voornaam: lid.voornaam,
        achternaam: lid.achternaam,
        adres: lid.adres,
        woonplaats: lid.woonplaats,
        geboortedatum: lid.geboortedatum,
        telnummer: lid.telnummer,
        geslacht: lid.geslacht,
        email: lid.email,
        icenaam1: lid.icenaam1,
        icenummer1: lid.icenummer1,
        icenaam2: lid.icenaam2,
        icenummer2: lid.icenummer2,
        emailouders: lid.emailouders
      }

    }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        return (error.response)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      console.log(error.config)
    })
  }
}

export default new LidService()
